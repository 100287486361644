import React, { ReactElement } from 'react';
import { useTranslation } from 'next-i18next';
import cls from 'classnames';
import styles from './style.module.scss';
import { LeaveProps } from '../../Home/interface';
import { convertMinutesToHoursAndMinutes, formatDateAndWeekAndTime } from '@/utils/date';

interface Props extends LeaveProps {
  header: ReactElement;
  bodyBottom?: ReactElement | null;
  footerBottom?: ReactElement | null;
}

const CommonDetail = (props: Props) => {
  const { t, i18n } = useTranslation('home');
  const {
    classType,
    reason,
    lessonTitle,
    lessonUnitTitle,
    lessonTeacherName,
    lessonStartDateTime = '',
    whenCreated,
    duration,
    header,
    bodyBottom = null,
    footerBottom = null,
  } = props;

  const boxItemWidth = i18n.language === 'en' ? '90px' : 'auto';

  const getDuration = (duration: number) => {
    if (!duration) {
      return '-';
    }
    const [hours, minutes] = convertMinutesToHoursAndMinutes(duration);
    return hours === 0
      ? `${minutes}${i18n.language === 'en' ? '-' : ''}${t('自主请假-分钟')}`
      : `${hours}${t('自主请假-小时')} / ${minutes}${t('自主请假-分钟')}`;
  };

  return (
    <div className={styles.boxWrapper}>
      {header}
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('请假课程')}:</span>
        <span className={styles.itemTip}>
          {lessonUnitTitle?.split('-').join(' ')} - {lessonTitle}
        </span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('课程类型')}: </span>
        <span className={styles.itemTip}>{classType}</span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('主讲老师')}:</span>
        <span className={styles.itemTip}>{lessonTeacherName}</span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('自主请假-上课时长')}:</span>
        <span className={cls(styles.itemTip)}>{getDuration(duration as number)}</span>
      </div>
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('自主请假-原上课时间')}:</span>
        <span className={cls(styles.itemTip)}> {formatDateAndWeekAndTime(lessonStartDateTime, i18n.language)}</span>
      </div>
      {bodyBottom}
      <div className={styles.line} />
      <div className={styles.boxItem}>
        <span style={{ width: boxItemWidth }}>{t('请假申请')}:</span>
        <span className={styles.itemTip}>{reason}</span>
      </div>
      <div className={cls(styles.boxItem, 'last:mb-0')}>
        <span style={{ width: boxItemWidth }}>{t('请假时间')}:</span>
        <span className={styles.itemTip}>{formatDateAndWeekAndTime(whenCreated as string, i18n.language)}</span>
      </div>
      {footerBottom}
    </div>
  );
};

export default CommonDetail;
